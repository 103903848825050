.App{
    height: 100vh;
    display: flex;
    background-color: #020230;
    color: white;
}

.main{
    width: 75%;
    display: flex;
    flex-direction: column;
    background: linear-gradient(to bottom, rgba(0,0,0,0), #020230), url("./assets/milionairaararra.jpg") center; 
}

.top{
height: 50%;
position: relative;
}

.timer{
    width: 70px;
    height: 70px;
    border: 5px solid white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-weight: 700;
    position: absolute;
    bottom: 10px;
    left: 80px;
}

.bottom{
    height: 50%;
}

.trivia{
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
}

.questions{
    width: 80%;
    background: linear-gradient(#100241, black);
    border: 2px solid white;
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    font-size: 20px;
}

.answers{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.answer{
    width: 40%;
    padding: 10px;
    text-align: center;
    background: linear-gradient(#0e0124, #22074d);
    border: 1px solid white;
    border-radius: 15px;
    font-size: 20px;
    font-weight: 300;
    cursor: pointer;
    margin: 0 10px 20px 10px;
}

.answer:hover,
.answer.active{
    background: mediumblue;
}

.answer.correct{
    animation: correct 2s ease forwards;
}

@keyframes correct {
    0%,
    22%,
    42% {
        background: mediumblue;
    }

    20%,
    40%,
    60%  {
        background: linear-gradient(#0e0124, #22074d);
    }
    62%,
    100%{
        background: green;
    }
}

.answer.wrong {
    animation: wrong 2s ease forwards;
  }
  
  @keyframes wrong {
    0%,
    22%,
    42% {
      background: mediumblue;
    }
    20%,
    40%,
    60% {
      background: linear-gradient(#0e0124, #22074d);
    }
    62%,
    100% {
      background: crimson;
    }
  }

.pyramid{
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
} 

.moneyList{
    list-style: none;
    width: 100%;
    padding: 20px;
}

.moneyListItem{
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
}

.moneyListItem.active{
    background-color: teal;
}

.moneyListItemNumber{
    font-size: 18px;
    font-weight: 100;
    width: 30%;
}

.moneyListItemAmount{
    font-size: 20px;
    font-weight: 300;
}

.earn-text{
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.start{
    width: 250px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}




.startInput{
    width: 100%;
    height: 30px;
    border: none;
    border-radius: 5px;
    text-align: center;
    font-size: center;
    font-size: 18px;
    border-radius: 20px;
    padding: 30px;
    color: black;
    background: linear-gradient(to top, yellow, rgb(106, 106, 179));
    
}

.startInput:hover{
    background: transparent;
    border: 2px solid white;
    color: aliceblue;
    border-radius: 20px;
}

.startInput:focus{
    outline: none;
}

.startButton{
    width: 100%;
    height: 32px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 22px;
    font-family: cursive;
    background: linear-gradient(to bottom, yellow, rgb(106, 106, 179));
    font-weight: 500;
    margin: 20px;
    padding: 30px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.startButton:hover{
    background: transparent;
    border: 2px solid white;
    color: aliceblue;
    border-radius: 20px;
}

